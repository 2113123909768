import { isDefinedAndIsNotNaN } from '~/utils/extra';

const ruNumberFormat = new Intl.NumberFormat('ru-RU');
const FRACTION_DIGITS = 1;

export function useNumberFormatter() {
  const { currentCountry } = useInternational();

  // eslint-disable-next-line default-param-last
  function numberToCurrency(num: number, withSymbol = true, maximumFractionDigits = 0) {
    const currency = currentCountry.value?.currency?.isoCode || 'RUB';
    const currencySymbol = currentCountry.value?.currency?.sign;
    const number = num && !Number.isNaN(num) ? num : 0;
    const options = currencySymbol ? {
      minimumFractionDigits: 0,
      maximumFractionDigits,
    } : {
      maximumFractionDigits,
      currency,
      minimumFractionDigits: 0,
      style: 'currency',
      currencyDisplay: 'symbol',
    };
    const result = number.toLocaleString('ru-RU', options);

    return withSymbol ? `${result} ${currencySymbol}` : result;
  }

  function numberFormat(value: NotDefined<number>, fractionDigits = FRACTION_DIGITS) {
    return isDefinedAndIsNotNaN(value) ? ruNumberFormat.format(Number(value.toFixed(fractionDigits))) : '';
  }

  return {
    numberToCurrency,
    numberFormat,
  };
}
